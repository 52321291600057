import styled from "styled-components";
import Container from "../Core/Container";
import Breakpoints from "../../theme/breakpoints";

export const ServicesContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  @media (max-width: ${Breakpoints.lg}) {
    padding: 50px 32px;
  }
`;

export const ServicesWrapper = styled.section`
  display: flex;
  align-items: center;
`;

export const HeaderSection = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

export const Title = styled.h2`
  font-size: 34px;
  margin-bottom: 8px;
  line-height: 42px;
  text-transform: capitalize;
  position: relative;
  font-weight: 800;
`;

export const SmallText = styled.p`
  font-size: 16px;
  opacity: 0.6;
  margin: 0;
`;

export const ServiceAndTech = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: ${Breakpoints.lg}) {
    flex-direction: column-reverse;
  }
`;

export const LeftSection = styled.div`
  width: 50%;
  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
  }
`;

export const RightSection = styled.div`
  width: 50%;
  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
    margin-bottom: 32px;
  }
`;

export const TechLogos = styled.div`
  text-align: center;
  img {
    padding: 5px 10px;
  }
`;

export const ServiceHeading = styled.h3`
  font-size: 28px;
  margin-bottom: 8px;
  line-height: 42px;
  text-align: center;
  text-transform: capitalize;
`;

export const Services = styled.div`
  padding: 0 20px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 24px;
  @media (max-width: ${Breakpoints.md}) {
    grid-template-columns: auto;
  }
`;

export const Service = styled.div`
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  /* height: 200px; */
  box-shadow: 0px 3px 5px #00000017;
`;

export const ServiceTitle = styled.h3`
  font-size: 21px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 10px;
`;

export const SerivceList = styled.h3`
  margin: 0;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;
