import styled from "styled-components";
import Breakpoints from "../../theme/breakpoints";

const containerSizes = {
  sm: "540px",
  md: "720px",
  lg: "960px",
  xl: "1140px",
  xxl: "1320px",
};

const Container = styled.div`
  width: 100%;
  /* padding: 0 20px; */
  margin: 0 auto;
  @media (min-width: ${Breakpoints.sm}) {
    width: ${containerSizes.sm};
  }
  @media (min-width: ${Breakpoints.md}) {
    width: ${containerSizes.md};
  }
  @media (min-width: ${Breakpoints.lg}) {
    width: ${containerSizes.lg};
  }
  @media (min-width: ${Breakpoints.xl}) {
    width: ${containerSizes.xl};
  }
  @media (min-width: ${Breakpoints.xxl}) {
    width: ${containerSizes.xxl};
  }
`;

export default Container;
