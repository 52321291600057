import IntroSection from "../IntroSection/IntroSection";
import ServicesSection from "../ServicesSection/ServicesSection";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import About from "../About/About";

const Home = () => {
  return (
    <>
      <Header />
      <IntroSection />
      <ServicesSection />
      <About />
      <Footer />
    </>
  );
};

export default Home;
