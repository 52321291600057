import styled from "styled-components";
import Container from "../Core/Container";
import Breakpoints from "../../theme/breakpoints";

export const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.lg}) {
    padding: 32px 0;
  }
  @media (max-width: ${Breakpoints.md}) {
    justify-content: center;
  }
`;

export const HeaderWrapper = styled.section`
  @media (max-width: ${Breakpoints.md}) {
    box-shadow: 0 1px 4px 0px #e6e7e7;
  }
`;

export const LogoContainer = styled.div`
  img {
    width: 180px;
  }
`;

export const MenuContainer = styled.div`
  padding: 0 20px;
  @media (max-width: ${Breakpoints.lg}) {
    a:last-child {
      display: none;
    }
  }
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`;

export const MenuItem = styled.a`
  text-transform: uppercase;
  padding: 20px;
  font-size: 16px;
  text-decoration: none;
  color: #081828;
`;
