import styled from "styled-components";
import Container from "../Core/Container";
import Breakpoints from "../../theme/breakpoints";

export const IntroContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding: 50px 0;
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    padding: 50px 32px;
  }
`;

export const IntroWrapper = styled.section`
  display: flex;
  align-items: center;
`;

export const LeftSection = styled.div`
  width: 40%;
  @media (max-width: ${Breakpoints.lg}) {
    width: 50%;
  }
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
  }
`;

export const RightSection = styled.div`
  width: 60%;
  img {
    width: 100%;
    height: 100%;
    transform: scale(1.2);
  }
  @media (max-width: ${Breakpoints.lg}) {
    width: 50%;
    img {
      width: 100%;
      height: 100%;
      transform: scale(1);
    }
  }
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: 800;
  line-height: 50px;
  text-shadow: 0px 3px 8px #00000017;
  text-transform: capitalize;
  @media (max-width: ${Breakpoints.lg}) {
    font-size: 22px;
  }
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    line-height: 32px;
  }
`;
