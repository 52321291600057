import { AboutContainer, AboutWrapper, Title, SmallText } from "./About.styles";

const About = () => {
  return (
    <AboutWrapper>
      <AboutContainer id="about">
        <Title>about us</Title>
        <SmallText>
          We are group of dynamic and creative software developer, sepcialized
          in producing optimized & highly scalable application, designing cloud
          architecture, solving business problems for high-volume companies.
          Eager to support and solve problems with top-notch coding and DevOps
          skills.
        </SmallText>
      </AboutContainer>
    </AboutWrapper>
  );
};

export default About;
