import {
  HeaderContainer,
  HeaderWrapper,
  LogoContainer,
  MenuContainer,
  MenuItem,
} from "./Header.styles";
import { LinkButton } from "../Core/Input";

import LogoImg from "../../assets/logo.png";

const menuItems = [
  {
    label: "services",
    link: "#services",
  },
  {
    label: "about us",
    link: "#about",
  },
  {
    label: "contact us",
    link: "#footer",
  },
];

const Header = () => {
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <LogoContainer>
          <a href="/">
            <img
              src={LogoImg}
              width="180"
              height="49"
              alt="Quikdom, serverless consulting, development"
            />
          </a>
        </LogoContainer>

        <MenuContainer>
          {menuItems.map((menu, index) => (
            <MenuItem href={menu.link} key={index}>
              {menu.label}
            </MenuItem>
          ))}
          <LinkButton href="#footer">get in touch</LinkButton>
        </MenuContainer>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
