import styled from "styled-components";
import Container from "../Core/Container";
import Breakpoints from "../../theme/breakpoints";

export const AboutContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  @media (max-width: ${Breakpoints.lg}) {
    padding: 50px 32px;
  }
`;

export const AboutWrapper = styled.section`
  display: flex;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 34px;
  margin-bottom: 8px;
  line-height: 42px;
  text-transform: capitalize;
  position: relative;
  font-weight: 800;
`;

export const SmallText = styled.p`
  font-size: 16px;
  margin: 0;
  text-align: center;
`;
