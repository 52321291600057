import {
  ServicesContainer,
  ServicesWrapper,
  Title,
  SmallText,
  HeaderSection,
  LeftSection,
  RightSection,
  ServiceAndTech,
  TechLogos,
  ServiceHeading,
  Services,
  Service,
  SerivceList,
  ServiceTitle,
} from "./ServicesSection.styles";

import aws from "../../assets/aws.webp";
import docker from "../../assets/docker.webp";
// import go from "../../assets/go.webp";
import javascript from "../../assets/javascript.webp";
import mongodb from "../../assets/mongodb.webp";
import mysql from "../../assets/mysql.webp";
import nodejs from "../../assets/nodejs.webp";
import php from "../../assets/php.webp";
import postgresql from "../../assets/postgresql.webp";
import react from "../../assets/react.webp";
import webpack from "../../assets/webpack.webp";
import mocha from "../../assets/mocha.webp";
import jest from "../../assets/jest.webp";
import serverless from "../../assets/serverless.webp";
import express from "../../assets/express.webp";
import graphql from "../../assets/graphql.webp";

const ServicesSection = () => {
  return (
    <ServicesWrapper>
      <ServicesContainer>
        <HeaderSection id="services">
          <Title>Services</Title>
          <SmallText>
            We bring great value to the world with our experties
          </SmallText>
        </HeaderSection>
        <ServiceAndTech>
          <LeftSection>
            <HeaderSection>
              <ServiceHeading>Our Tech Stack</ServiceHeading>
              <SmallText>Solve your problem with morden technologies</SmallText>
            </HeaderSection>
            <TechLogos>
              <img
                src={serverless}
                width="341"
                height="106"
                alt="serverless developer"
              />
              <img
                src={nodejs}
                width="116"
                height="106"
                alt="nodejs developer"
              />
              <img src={react} width="116" height="106" alt="react developer" />
              <img src={php} width="116" height="106" alt="php developer" />
              {/* <img src={go} width="116" height="106" alt="golang developer" /> */}
              <img
                src={javascript}
                width="116"
                height="106"
                alt="javascript developer"
              />
              <img
                src={express}
                width="116"
                height="106"
                alt="nodejs developer"
              />
              <img
                src={graphql}
                width="116"
                height="106"
                alt="graphql api - save your resources"
              />
              <img
                src={aws}
                width="116"
                height="106"
                alt="aws cloud - solution architect/devops"
              />
              <img src={docker} width="116" height="106" alt="docker" />
              <img
                src={mongodb}
                width="116"
                height="106"
                alt="nosql database mongodb"
              />
              <img src={postgresql} width="116" height="106" alt="postgres" />
              <img src={mysql} width="116" height="106" alt="mysql" />
              <img
                src={mocha}
                width="116"
                height="106"
                alt="testing with mocha"
              />
              <img
                src={jest}
                width="116"
                height="106"
                alt="testing with jest"
              />
              <img
                src={webpack}
                width="116"
                height="106"
                alt="reduce size with webpack"
              />
            </TechLogos>
          </LeftSection>
          <RightSection>
            <HeaderSection>
              <ServiceHeading>Our Capabilities</ServiceHeading>
              <SmallText>
                Our work is the presentation of our capabilities
              </SmallText>
            </HeaderSection>
            <Services>
              <Service>
                <ServiceTitle>IT Development</ServiceTitle>
                <SerivceList>enterprise application</SerivceList>
                <SerivceList>web & mobile application</SerivceList>
                <SerivceList>Maintenance & optimisation</SerivceList>
                <SerivceList>Serverless Development</SerivceList>
              </Service>
              <Service>
                <ServiceTitle>DevOps as a Service</ServiceTitle>
                <SerivceList>Infrastructure setup</SerivceList>
                <SerivceList>Const Optimisation</SerivceList>
                <SerivceList>Infrastructure Optimisation</SerivceList>
              </Service>
              <Service
                style={{
                  gridColumn: "span 2",
                  width: "300px",
                  placeSelf: "center",
                }}
              >
                <ServiceTitle>Consultation</ServiceTitle>
                <SerivceList>it consultation</SerivceList>
                <SerivceList>cloud consultation</SerivceList>
              </Service>
            </Services>
          </RightSection>
        </ServiceAndTech>
      </ServicesContainer>
    </ServicesWrapper>
  );
};

export default ServicesSection;
