import {
  IntroWrapper,
  IntroContainer,
  LeftSection,
  RightSection,
  Title,
} from "./IntroSection.styles";

import { LinkButton } from "../Core/Input";

import IntroImg from "../../assets/brainstorm-idea.webp";

const IntroSection = () => {
  return (
    <IntroWrapper>
      <IntroContainer>
        <LeftSection>
          <Title>Build your production ready software Application.</Title>
          <LinkButton href="#footer">Get in Touch</LinkButton>
        </LeftSection>
        <RightSection>
          <img
            src={IntroImg}
            width="100"
            height="100"
            alt="Quikdom, serverless consulting, development"
          />
        </RightSection>
      </IntroContainer>
    </IntroWrapper>
  );
};

export default IntroSection;
