import styled from "styled-components";

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #7a7a7a;
  font-size: 16px;
  border-radius: 8px;
  line-height: 1.5;
  color: #495057;
`;

export const InputGroup = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

export const InlineFields = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const Label = styled.label`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 8px;
  font-weight: 600;
  display: inline-block;
`;

export const Textarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-family: inherit;
`;

export const Button = styled.button`
  border: none;
  border-radius: 24px;
  padding: 8px 12px;
  font-size: 16px;
  width: 160px;
  height: 48px;
  background: #0080ff;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  :hover {
    background: #4143e4;
  }
  cursor: pointer;
`;

export const LinkButton = styled.a`
  text-decoration: none;
  border: none;
  border-radius: 24px;
  padding: 12px 24px;
  font-size: 16px;
  width: 160px;
  height: 48px;
  background: #0080ff;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  :hover {
    background: #4143e4;
  }
  cursor: pointer;
  text-align: center;
`;
