import { useState } from "react";
import {
  FooterWrapper,
  FooterContainer,
  LeftSection,
  RightSection,
  CopyRight,
  FormContainer,
  LogoContainer,
  AddressContainer,
  Title,
  ShowError,
  EmailContainer,
  DesciptionText,
  ContactInfoTitle,
  SubmitContainer,
  ShowSuccess,
} from "./Footer.styles";

import {
  Input,
  InputGroup,
  Label,
  Textarea,
  InlineFields,
  Button,
} from "../Core/Input";

import axios from "axios";

import logo from "../../assets/logo.png";

const URL =
  "https://edble3vkfc.execute-api.ap-south-1.amazonaws.com/prod/contact";

const Footer = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");

  const [error, setError] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const clearFields = () => {
    setFirstname("");
    setLastname("");
    setEmail("");
    setQuery("");
  };

  const validEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^\S+@\S+\.\S+$/);
  };

  const validation = () => {
    let err = [];
    if (firstname === "") err.push("First Name required.");
    if (lastname === "") err.push("Last Name required.");
    if (email === "") {
      err.push("Email required.");
    } else if (!validEmail(email)) {
      err.push("Invalid email.");
    }
    if (query === "") err.push("Please describe your requirement.");
    return err;
  };

  const submit = () => {
    setError([]);
    setSuccessMessage("");
    const err = validation();
    if (err.length) {
      setError(err);
      return;
    }
    setLoading(true);

    const data = {
      firstname,
      lastname,
      email,
      description: query,
    };

    axios
      .post(URL, data)
      .then(() => {
        clearFields();
        setSuccessMessage(
          "Thank you for sharing your query with us, we will get in touch with you ASAP."
        );
      })
      .catch(() => {
        setError([
          "Something went wrong, please try again or send email to hello@quikdom.com",
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FooterWrapper id="footer">
      <FooterContainer>
        <LeftSection>
          <LogoContainer>
            <a href="/">
              <img
                src={logo}
                width="180"
                height="49"
                alt="Quikdom, serverless consulting, development"
              />
            </a>
          </LogoContainer>
          <DesciptionText>
            Skilled developers here to work with you on your future journey.
          </DesciptionText>

          <ContactInfoTitle>Contact Info</ContactInfoTitle>
          <AddressContainer>
            Mumbai, Maharashtra, India - 401305
          </AddressContainer>
          <EmailContainer>
            <a href="mailto:hello@quikdom.com">hello@quikdom.com</a>
          </EmailContainer>

          <ContactInfoTitle>Career</ContactInfoTitle>
          <EmailContainer>
            <a href="mailto:career@quikdom.com">career@quikdom.com</a>
          </EmailContainer>
        </LeftSection>
        <RightSection>
          <FormContainer>
            <Title>Let's build future together</Title>
            <InlineFields>
              <InputGroup>
                <Label>First Name</Label>
                <Input
                  type="text"
                  placeholder="Enter your first name"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  required
                />
              </InputGroup>
              <InputGroup>
                <Label>Last Name</Label>
                <Input
                  type="text"
                  placeholder="Enter your last name"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  required
                />
              </InputGroup>
            </InlineFields>
            <InputGroup>
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </InputGroup>
            <InputGroup>
              <Label>Any additional Information?</Label>
              <Textarea
                spellCheck
                rows={3}
                placeholder="Tell us about your requirement"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                required
              />
            </InputGroup>
            {error.length > 0 && (
              <ShowError>
                <ul>
                  {error.map((err, index) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              </ShowError>
            )}
            {successMessage !== "" && (
              <ShowSuccess>
                <ul>
                  <li>{successMessage}</li>
                </ul>
              </ShowSuccess>
            )}
            <SubmitContainer>
              <Button onClick={submit} disabled={loading}>
                {loading ? "loading ..." : "send enquiry"}
              </Button>
            </SubmitContainer>
          </FormContainer>
        </RightSection>
      </FooterContainer>
      <CopyRight> &copy; 2024-2025 Quikdom | All rights reserved</CopyRight>
    </FooterWrapper>
  );
};

export default Footer;
