import styled from "styled-components";
import Container from "../Core/Container";
import Breakpoints from "../../theme/breakpoints";

export const FooterContainer = styled(Container)`
  display: flex;
  color: #ffffff;
  padding: 50px 0;

  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column-reverse;
    padding: 50px 32px;
  }
`;

export const FooterWrapper = styled.section`
  background-color: #081828;
`;

export const LeftSection = styled.div`
  width: 50%;
  padding-top: 42px;
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    text-align: center;
  }
`;

export const RightSection = styled.div`
  width: 50%;
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  text-shadow: 0px 3px 8px #00000017;
  @media (max-width: ${Breakpoints.md}) {
    text-align: center;
  }
`;

export const FormContainer = styled.div`
  max-width: 500px;
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    margin-bottom: 32px;
  }
`;

export const LogoContainer = styled.div`
  margin-bottom: 16px;
  img {
    width: 180px;
  }
`;

export const CopyRight = styled.div`
  border-top: 1px solid #7a7a7a;
  padding: 10px 0;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
`;

export const AddressContainer = styled.div`
  margin-bottom: 16px;
`;

export const EmailContainer = styled.div`
  margin-bottom: 16px;

  a {
    text-decoration: none;
    color: #ffffff;
  }
`;

export const DesciptionText = styled.p`
  margin-bottom: 16px;
  width: 300px;
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
  }
`;

export const ShowError = styled.div`
  margin-bottom: 16px;
  background: #f8d7da;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  color: #721c24;
`;

export const ContactInfoTitle = styled.div`
  font-size: 24px;
  font-family: inherit;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 42px;
`;

export const SubmitContainer = styled.div`
  @media (max-width: ${Breakpoints.md}) {
    text-align: center;
  }
`;

export const ShowSuccess = styled.div`
  margin-bottom: 16px;
  background: #d4edda;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  color: #155724;
`;
